import axios from '@axios'
import { subscribeSymbol } from '@/services/binance/fut/binance'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getOrdensAbertas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/fut/ordem-entrada', { params: queryParams })
          .then(response => {
            // assinar as moedas presentes na ordem para receber informações
            response.data.content.forEach(currency => {
              subscribeSymbol(currency.moedaParidade)
            })

            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    cancelarOrdem(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/fut/ordem-entrada/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
