<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <b-table
      ref="refListTable"
      :items="dataList"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('futures.emptyText')"
      :sort-desc.sync="isSortDirDesc"
      striped
    >

      <template #thead-top>
        <b-tr>
          <b-th colspan="12">
            {{ totalCount }} {{ $t('futures.ordemAberta.ordensAbertas') }}
          </b-th>
        </b-tr>
      </template>

      <template #cell(paridade)="data">
        <div class="text-nowrap">
          <b-avatar
            size="sm"
            :src="getIcon(data.item.moedaTrade)"
          />
          <span class="text-nowrap">
            {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
          </span>
        </div>
      </template>

      <template #cell(ordemPosition)="data">
        <div class="text-nowrap">
          {{ data.value }}
          <b-badge variant="light-warning">
            {{ data.item.alavancagem }}x
          </b-badge>
        </div>
      </template>

      <template #cell(precoWs)="data">
        <template v-if="tickers[data.item.moedaParidade]!=null">
          {{ tickers[data.item.moedaParidade].price }}
        </template>
        <template v-else>
          <div class="text-center">
            <b-spinner small />
          </div>
        </template>
      </template>

      <template #cell(dataCriacao)="data">
        {{ formatDateTimeBR(data.value) }}
      </template>

      <template #cell(agente)="data">
        {{ data.value }} <br>
        <b-badge variant="light-primary">
          #{{ data.item.sinalId }}
        </b-badge>
      </template>

      <!-- Column: Actions -->
      <template #cell(acao)="data">

        <div class="text-nowrap">
          <feather-icon
            :id="`compra-aberta-row-${data.item.id}-cancelar-icon`"
            icon="Trash2Icon"
            class="cursor-pointer"
            size="16"
            @click.stop="confirmarCancelarOrdem(data.item.id)"
          />
          <b-tooltip
            :title="$('futures.ordemAberta.tooltipCancelarOrdem')"
            class="cursor-pointer"
            :target="`compra-aberta-row-${data.item.id}-cancelar-icon`"
          />
        </div>
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('futures.textMuted', { from: paginationMeta.from, to: paginationMeta.to, of: paginationMeta.of }) }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalCount"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>

</template>

<script>

import {
  BCard, BTable, BAvatar, BPagination, BRow, BCol, BSpinner, BTooltip, BTr, BTh, BBadge,
} from 'bootstrap-vue'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import { formatDateTimeBR } from '@/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ordemAbertaStoreModule from './ordemAbertaStoreModule'

const ORDEM_ABERTA_FUT_STORE_MODULE_NAME = 'ordem-aberta-fut'

export default {

  components: {
    BCard,
    BTable,
    BAvatar,
    BPagination,
    BRow,
    BCol,
    BSpinner,
    BTooltip,
    BTr,
    BTh,
    BBadge,
  },

  mixins: [utilsMixin],

  data() {
    return {
      polling: null,
      formatDateTimeBR,
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      sortBy: 'id,desc',
      isSortDirDesc: true,
      dataList: [],
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'paridade', label: this.$i18n.t('futures.ordemAberta.tableHeader.paridade') },
        { key: 'ordemTipo', label: this.$i18n.t('futures.ordemAberta.tableHeader.ordemTipo') },
        { key: 'ordemPosition', label: this.$i18n.t('futures.ordemAberta.tableHeader.ordemPosition') },
        { key: 'quantidade', label: this.$i18n.t('futures.ordemAberta.tableHeader.quantidade') },
        { key: 'compraAte', label: this.$i18n.t('futures.ordemAberta.tableHeader.compraAte') },
        { key: 'precoWs', label: this.$i18n.t('futures.ordemAberta.tableHeader.precoWs') },
        { key: 'dataCriacao', label: this.$i18n.t('futures.ordemAberta.tableHeader.dataCriacao') },
        { key: 'agente', label: this.$i18n.t('futures.ordemAberta.tableHeader.agente') },
        { key: 'acao', label: this.$i18n.t('futures.ordemAberta.tableHeader.acao') },
      ]
    },
    tickers() {
      return this.$store.state.binanceFut.tickers
    },

    paginationMeta() {
      const localItemsCount = this.$refs.refListTable ? this.$refs.refListTable.localItems.length : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalCount,
      }
    },
  },

  watch: {
    currentPage() {
      this.$refs.refListTable.refresh()
    },

    perPage() {
      this.$refs.refListTable.refresh()
    },
  },

  created() {
    if (!store.hasModule(ORDEM_ABERTA_FUT_STORE_MODULE_NAME)) store.registerModule(ORDEM_ABERTA_FUT_STORE_MODULE_NAME, ordemAbertaStoreModule)
    this.pollfetchList()
  },

  beforeDestroy() {
    clearInterval(this.polling)
    if (store.hasModule(ORDEM_ABERTA_FUT_STORE_MODULE_NAME)) store.unregisterModule(ORDEM_ABERTA_FUT_STORE_MODULE_NAME)
  },

  methods: {

    fetchList() {
      store
        .dispatch('ordem-aberta-fut/getOrdensAbertas', {
          size: this.perPage,
          page: this.currentPage - 1,
          sortBy: this.sortBy,
          sortDesc: this.isSortDirDesc,
          status: 'aberta',
        })
        .then(response => {
          const { content, totalElements } = response.data
          this.dataList = content
          this.totalCount = totalElements
          if (content && content[0] && content[0].bloqueiaControle) {
            this.tableColumns = this.tableColumns.filter(column => column.key !== 'acao')
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao recuperar a lista.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },

    confirmarCancelarOrdem(id) {
      this.$swal({
        title: 'Tem certeza que deseja cancelar a ordem?',
        text: 'Sua ordem será cancelada na corretora.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, pode cancelar!',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.cancelarOrdem(id)
        }
      })
    },

    cancelarOrdem(id) {
      this.$store.dispatch('ordem-aberta-fut/cancelarOrdem', id)
        .then(() => {
          this.fetchList()
          this.$swal({
            icon: 'success',
            title: 'Cancelada!',
            text: 'Sua ordem foi cancelada com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(e => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao Cancelar',
            text: e.data,
            customClass: {
              confirmButton: 'btn btn-error',
            },
          })
        })
    },

    pollfetchList() {
      this.fetchList()
      const self = this
      this.polling = setInterval(() => {
        self.fetchList()
      }, 6000)
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
